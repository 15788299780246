.surveyCompleted {
	width: calc(100% - 300px);
	color: #fff;
	display: flex;
	text-align: center;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 60px);
	background: linear-gradient(#111, #333);
}

@media (max-width: 768px) {
  .surveyCompleted {
    width: 100%;
  }
}

.surveyCompleted .box {
	margin: 0 auto;
	max-width: 960px;
}

.surveyCompleted .box img {
	margin-top: 60px;
}

.surveyCompleted .box .surveyCompletedFooter {
	gap: 30px;
	padding: 15px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.thanksBox {
	margin: 30px 30px 100px 30px;
}

.thanksBox h1 {
	font-size: 26px;
	margin-bottom: 20px;
}

.thanksBox h2 {
	font-size: 18px;
	color: #C4C4C4;
	font-weight: normal;
	margin-bottom: 30px;
}

.thanksBox h3 {
	font-size: 14px;
	color: #C4C4C4;
	line-height: 1.6;
	font-weight: normal;
}