.inputMasked {
  background-color: #fafbff;
  border: 0.5px solid #6c6c73;
  border-radius: 5px;
  /* width: 100%; */
  height: 35px;
  font-family: "Roboto", sans-serif;
  padding: 10px 5px;
}

.containerInputMasked {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.inputDate {
  background-color: #fafbff;
  border: 0.5px solid #6c6c73;
  border-radius: 5px;
  /* width: 100%; */
  height: 35px;
  font-family: "Roboto", sans-serif;
  padding: 10px 5px;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 12pt;
}