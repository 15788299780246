.progressBarContainer {
  gap: 10px;
  width: 100%;
  display: flex;
  max-width: 600px;
  flex-direction: column;
  justify-content: baseline;
}

.progressBarContainer .bar {
  height: 6px;
  width: 100%;
  border-radius: 6px;
  background: #c4c4c4;
}

.progressBarContainer .bar .progress {
  height: 100%;
  border-radius: 6px;
  background: #368ff9;
}

.progressBarContainer .informations {
  display: flex;
  justify-content: space-between;
}

p.dark {
  color: #6c6c73;
}

p.light {
  color: #ffffff;
}