.containerHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  padding: 25px 0px;
  align-items: center;
}

.containerHeader .backArrow {
  position: absolute;
  left: 15px;
  z-index: 10;
  cursor: pointer;
}

.containerHeader .closeButton {
  position: absolute;
  right: 15px;
  z-index: 10;
  cursor: pointer;
}

.backIcon {
  cursor: pointer;
}

/* modal */
.containerModal {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  gap: 15px;
  padding: 0px 25px;
  max-width: 450px;
}

.containerModal h1 {
  text-align: center;
  font-size: 28px;
  margin: 0px;
}

.containerModal h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: center;
}

.containerModal .wrapperButton {
  align-self: center;
  display: flex;
  gap: 10px;
}

/* @media only screen and (max-width: 600px) {
  .containerModal .wrapperButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
} */

.containerModal .gifClose {
  width: 180px;
  align-self: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 960px) {
  .containerModal .gifClose {
    width: 145px;
    align-self: center;
  }
}

.logo {
  width: 34px;
}