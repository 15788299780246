button {
  /* height: 30px; */
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 12px 20px;
}

button p {
  font-size: 15px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}

button.lightTransparent {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: transparent;
  justify-content: center;
  border: 1px solid #fff;
}

button.lightTransparent:hover {
  background: #368ff9;
  border-color: transparent;
}

button.lightTransparent p {
  color: #fff;
}

button.blueWhite {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #368ff9;
  justify-content: center;
  border: 1px solid #368ff9;
}

button.blueWhite p {
  color: #fff;
}

button.darkTransparent {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: transparent;
  justify-content: center;
  border: 1px solid #6c6c73;
}

button.darkTransparent p {
  color: #6c6c73;
}

button.lightBlue {
  border: none;
  display: flex;
  padding: 15px 10px;
  /* padding: 20px; */
  background: #fff;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
}

button.lightBlue:hover {
  background: #368ff9;
  border-color: transparent;
}

button.lightBlue p {
  font-size: 16px;
  color: #368ff9;
}

button.lightBlue:hover p {
  color: #fff;
}

img.loadingGif {
  width: 20px;
  height: 20px;
  margin: 0px !important;
}
