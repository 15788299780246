.container {
  width: calc(100%);
  transition: width 0.2s ease-in;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 21pt;
}

.containerWithAssistant {
  width: calc(100% - 300px);
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}

.container .box {
  max-width: 1140px;
  margin-bottom: 20px;
}

.container .box img {
  margin-top: 60px;
}

.container .box .footerContainer {
  gap: 30px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.askBox {
  margin: 30px 25px 0px 25px;
}

.askBox h1 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
  text-align: center;
}

.askBox h2 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  margin-bottom: 20px;
}

.askBox h3 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.6;
  font-weight: normal;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
}

.containerHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  padding: 30px 0px;
  align-items: center;
}

.containerHeader .backArrow {
  position: absolute;
  left: 15px;
  z-index: 10;
  cursor: pointer;
}

.selectedQuestions {
  border: 2px solid;
  border-image: linear-gradient(to right, #9348ff, #6e44ea) 1;
  padding: 15px;
  border-radius: 50%;
  transition: border-image 0.3s ease;
  margin-top: 30px;
}

.unselectedQuestions {
  border: 2px solid transparent;
  transition: border-image 0.3s ease;
  margin-top: 20px;
}

.unselectedQuestions:hover {
  border-color: rgba(147, 72, 255, 0);
  transition: border 0.3s ease;
}

textarea {
  width: 100%;
  padding: 15px !important;
  border: 1px solid #ccc !important;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  background-color: #f9f9f9;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

textarea:focus, textarea:hover {
  border-color: #3e3e3e !important;
}

textarea:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

p {
  padding-left: 0 !important;
}