.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageLoading {
  width: 50px;
}

.loadingMessage {
  text-align: center;
  font-size: 16px;
}
