* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-position: center;
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* background-image: url("./assets/bg.jpg"); */
  background-repeat: repeat;
  background-color: #fafbff;
}

label {
  font-size: 15px;
}

.back-icon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.back-icon:hover {
  transform: scale(1.1);
}

::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 650px) {
  p {
    font-size: 14pt !important;
  }
  label {
    font-size: 14pt !important;
  }
  h1 {
    font-size: 18pt !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999;
  box-shadow: inset 0 0 100px 40px rgb(0 0 0 / 8%);
}



/* AI Assistant */
/* ChatWidget.css */
.chat-widget {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: calc(100% - 15px);
  border: 1px solid #333;
  border-radius: 10px 0 0 10px;
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #333;
  background-color: #111;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.chat-header-title h3 {
  background: linear-gradient(to right, #9348ff, #00ffe5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chat-close-button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.chat-body {
  padding: 10px;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.chat-message.assistant {
  text-align: left;
  background-color: #333;
}

.chat-message.user {
  text-align: right;
  background-color: #444;
}

.chat-footer {
  padding: 10px;
  border-top: 1px solid #333;
  background-color: #111;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
}

.chat-input {
  width: calc(100% - 80px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #222;
  color: #fff;
}

.chat-send-button {
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.buttonContainer {
  text-align: right;
  margin-bottom: 5px;
}