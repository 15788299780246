.chatWidgetContainer {
  transition: width 0.2s ease-in;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: calc(100% - 0px);
  border: 1px solid #333;
  border-radius: 10px 0 0 10px;
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 1000;
}

.active {
  transition: width 0.2s ease-in;
  width: 350px;
  opacity: 1;
}

.chatWidget {
  height: calc(100% - 0px);
}

@media (max-width: 768px) {
  .chatWidget {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.chatHeader {
  padding: 10px;
  border-bottom: 1px solid #333;
  background-color: #111;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .chatHeader {
    border-top-left-radius: 0;
  }
}

.chatHeaderTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.chatHeaderTitle h3 {
  background: linear-gradient(to right, #9348ff, #00ffe5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chatCloseButton {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.chatBody {
  padding: 10px;
  height: calc(100% - 110px);
  overflow-y: auto;
}



.chatMessage {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}


.chatMessage.assistant {
  text-align: left;
  background-color: #333;
}

.chatMessage.user {
  text-align: right;
  background-color: #252525;
}

.chatMessageRole {
  font-weight: bold;
  font-size: 8pt;
  margin: 5px;
}

.chatMessageContent {
  font-size: 11pt !important;
  line-height: 1.5;
}

.chatMessageContent ol,
.chatMessageContent ul {
  margin-left: 28px;
}

.userRole {
  color: #83affc;
}


.assistantRole {
  background: linear-gradient(to right, #31fdbf, #775bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chatMessageContent {
  color: #fff;
  font-size: 12pt;
}

.chatFooter {
  padding: 10px;
  border-top: 1px solid #333;
  background-color: #111;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .chatFooter {
    border-bottom-left-radius: 0;
  }
}

.chatInput {
  width: calc(100% - 86px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #222;
  color: #fff;
  margin-left: 10px;
  font-size: 12pt;
}

.chatSendButton {
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}


.aiAssistantButton {
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to right, #9348ff, #04dfe2);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.aiAssistantButtonText {
  color: #fff;
  font-weight: bold;
  font-size: 8pt;
  margin-bottom: -5px;
}

.aiAssistantButtonIcon {
  color: #aaff2b;
  margin-right: 5px;
  margin-bottom: -2px;
}

.chatSubQuestionText {
  padding: 0 15px;
  margin-bottom: 15px;
  text-align: justify;
}

.chatSubQuestionText * {
  color: #fff !important;
}

.chatNoMessages {
  color: #fff;
  margin: 15px 0;
  text-align: center;
  background: linear-gradient(to right, #8ebfff, #00ffe5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 10pt;
}

.loadingBarContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-size: 8pt;
  text-align: center;
}

.loadingBar {
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #007bff, #00ffe5, #007bff);
  background-size: 200% 100%;
  animation: loading 2s linear infinite;
  border-radius: 5px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.errorMessage {
  color: #ee0927;
  font-size: 8pt;
  font-weight: bold;
  text-align: center;
  background: #2c2c2c;
  padding: 15px 10px;
  border-radius: 5px;
}