textarea.inputText {
  background-color: #fafbff;
  border: 0.5px solid #6c6c73;
  border-radius: 5px;
  width: 100%;
  min-height: 125px;
  max-height: 125px;
  font-family: "Roboto", sans-serif;
  padding: 10px 5px;

}

.container {
  margin-top: 15px;
}