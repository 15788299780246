.title {
  color: #000;
  margin-bottom: 25px;
  text-align: left;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.wrapperCheck {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.containerCheck {
  border-radius: 20px;
  border: 0.5px solid #6c6c73;
  color: #6c6c73;
}

.containerCheckActive {
  border-radius: 20px;
  background-color: #272727;
  color: #fff;
}
