.surveys {
  width: 100%;
  padding: 16px;
  display: flex;
  min-height: calc(100vh - 130px);
  /* max-width: 960px; */
  margin: 20px auto;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.containerLoaging {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.surveys img {
  margin-right: 26px;
}

.surveys .informationBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto 0 auto;
  max-width: 1100px;
}

.surveys .informationBox h1 {
  color: #222;
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 6px;
}

.surveys .informationBox h2 {
  color: #222;
  line-height: 1.5;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 20px;
}

.surveys .surveysContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 0px 15px;
  width: 100%;
  /* overflow-y: hidden; */
}

.surveys .surveysContainer .surveyBox {
  display: flex;
  border: none;
  color: #fff;
  padding: 25px 30px;
  height: 280px;
  margin: 20px 0;
  width: 300px;
  /* flex: 0 0 250px; */
  cursor: pointer;
  text-align: left;
  border-radius: 15px;
  flex-direction: column;
  justify-content: baseline;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(to right, #111, #333);
}

.surveys .surveysContainer .surveyBox h3 {
  font-size: 16px;
  margin-bottom: 20px;
}

.surveys .surveysContainer .surveyBox h4 {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
}

.surveys .surveysContainer .surveyBox:hover {
  margin: 20px 12px;
  transform: scale(1.01);
}

.surveys .informationBox h3,
.surveys .helpText h4 {
  color: #333;
  line-height: 1.5;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 6px;
  max-width: 1100px;
  margin-bottom: 50px;
}

.surveyBoxStatus {
  margin-left: auto;
  display: flex;
  width: 145px;
  color: #333;
  font-weight: 500;
  padding: 8px 12px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.surveyBoxStatus > div {
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-radius: 50%;
}

.surveyBoxStatus > p {
  font-size: 12px;
  font-weight: 600;
}

.surveyBoxProgress {
  width: 100%;
  margin-top: auto;
}

.surveyBoxProgress p {
  color: white;
}

@media only screen and (max-width: 960px) {
}
