.survey {
  width: 100%;
  min-height: calc(100vh - 60px);

  /* padding: 20px; */
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  /* background: linear-gradient(#fff, #ccc); */
  background-color: #f2f2f7;
  /* padding-bottom: 55px; */
}

.survey .box {
  width: 100%;
  margin: 0 auto;
  /* max-width: 960px; */
}

.survey .box header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.survey .box header > img:last-of-type {
  opacity: 0;
}

.survey .surveyIconContainer {
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.surveyContent {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex: 1;
  /* max-width: 960px; */
  padding: 50px 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* border-radius: 15px 15px 0 0; */
  background: linear-gradient(180deg, #121213 0%, #303033 100%);
}

.surveyContent > h1 {
  font-size: 30px;
  text-align: center;
}

.surveyContent > h2 {
  font-size: 20px;
  margin-top: 20px;
  font-weight: normal;
}

.surveyContent .statusBox {
  display: flex;
  color: #333;
  font-weight: 500;
  margin-top: 20px;
  padding: 10px 14px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.surveyContent .statusBox > div {
  width: 8px;
  height: 8px;
  margin-right: 14px;
  border-radius: 50%;
}

.surveyContent h3 {
  font-size: 16px;
  line-height: 1.7;
  text-align: center;
  font-weight: normal;
}

.surveyContentFooter {
  width: 100%;
  margin-top: 40px;
  max-width: 400px;
}

.surveyContentFooter > button {
  margin-bottom: 30px;
}

.backIcon {
  cursor: pointer;
}

.gifOpen {
  width: 185px;
}

.surveyContainerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 960px;
}

@media only screen and (max-width: 960px) {
  .gifOpen {
    width: 145px;
  }
}
