.title {
  color: #000;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: left;
}

.inputNumber {
  background-color: transparent;
  border: 0.5px solid #6c6c73;
  border-radius: 5px;
  height: 35px;
  font-family: "Roboto", sans-serif;
  padding: 10px 5px;
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
}

.containerInputNumber {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}