.container {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  display: flex;
  padding: 10px 20px;
  justify-content: space-evenly;
  align-items: center;
}

.container p {
  color: #6c6c73;
  font-size: 15px;
  word-wrap: break-word;
}

.container .buttonSurvey {
  border: 0px solid transparent;
  background-color: transparent;
  color: #368ff9;
  font-size: 15px;
}

.container .wrapperTitle {
  flex: 3;
}

.container .wrapperButton {
  display: flex;
  flex: 1;
}

.container .buttonLine {
  margin-right: 15px;
}
